import { defineStore } from 'pinia';
import { EXCHANGE_NAME } from '@/types/exchange';
import { useUserSettingsStore } from '@/stores/user/settings';
import { useWebSocketStore } from '@/stores/user/ws';
import { SIDE } from '@/types/account';
import { ChosenSymbol } from '@/types/settings';
import {
  OrderbookLiquidity, OrdersbooksState, OrderbookType, OrderbookRowChange, OrderbookServerResp,
  OrderbookChangesServerResp, OrderbookTypeServer, OrderbookLiquidityServerResp, AbstractQuantity,
} from '@/types/orderbooks';
import { tidyNumber } from '@/utilities';

function convertOrderbookTypeServer(
  orderbook: OrderbookTypeServer, exchangeName: string, exchangeType: string, symbol: string,
): OrderbookType {
  const userSettingsStore = useUserSettingsStore();

  if (exchangeName === EXCHANGE_NAME.BITFINEX) {
    symbol = symbol.substring(1);
  }

  const orderbookBuys: Record<string, AbstractQuantity> = {};
  const orderbookSells: Record<string, AbstractQuantity> = {};

  for (const [price, quantity] of Object.entries(orderbook.buys)) {
    const quantityStr = String(quantity);

    orderbookBuys[tidyNumber(price)] = {
      quantity: quantityStr,
      chosenAssetQuantity: String(userSettingsStore.convertSymbolQuantityToChosenAsset(
        parseFloat(quantityStr),
        parseFloat(price),
        new ChosenSymbol(exchangeName, exchangeType, symbol),
      )),
    };
  }

  for (const [price, quantity] of Object.entries(orderbook.sells)) {
    const quantityStr = String(quantity);

    orderbookSells[tidyNumber(price)] = {
      quantity: quantityStr,
      chosenAssetQuantity: String(userSettingsStore.convertSymbolQuantityToChosenAsset(
        parseFloat(quantityStr),
        parseFloat(price),
        new ChosenSymbol(exchangeName, exchangeType, symbol),
      )),
    };
  }

  return new OrderbookType(
    exchangeName,
    exchangeType,
    symbol,
    true,
    orderbookBuys,
    orderbookSells,
    orderbook.incompleteBuys,
    orderbook.incompleteSells,
  );
}


export const useOrderbooksStore = defineStore('orderbooks', {
  state: (): OrdersbooksState => ({
    orderbooks: {}, // Full orderbooks
    orderbooksLiquidity: {}, // Abstracted orderbooks (for bulk liquidity checks)
  }),
  actions: {
    setOrderbookLiquidity(body: OrderbookLiquidityServerResp) {
      const liquidity = new OrderbookLiquidity(
        body.exchangeName, body.exchangeType, body.symbol, body.consistentSymbol, body.buysTotal, body.sellsTotal,
      );

      this.orderbooksLiquidity[liquidity.exchangeName + liquidity.exchangeType + liquidity.symbol] = liquidity;
    },
    addRealtimeOrderbooks(
      body: {
        exchangeName: string,
        exchangeType: string,
        realtimeOrderbooks: Record<string, OrderbookType>
      },
    ) {
      const settingsStore = useUserSettingsStore();

      for (const symbol in body.realtimeOrderbooks) {
        const orderbook = body.realtimeOrderbooks[symbol];

        for (const [price, quantity] of Object.entries(orderbook.buys)) {
          const quantityStr = String(quantity);

          orderbook.buys[tidyNumber(price)] = {
            quantity: quantityStr,
            chosenAssetQuantity: String(settingsStore.convertSymbolQuantityToChosenAsset(
              parseFloat(quantityStr),
              parseFloat(price),
              new ChosenSymbol(body.exchangeName, body.exchangeType, symbol),
            )),
          };
        }

        for (const [price, quantity] of Object.entries(orderbook.sells)) {
          const quantityStr = String(quantity);

          orderbook.sells[tidyNumber(price)] = {
            quantity: quantityStr,
            chosenAssetQuantity: String(settingsStore.convertSymbolQuantityToChosenAsset(
              parseFloat(quantityStr),
              parseFloat(price),
              new ChosenSymbol(body.exchangeName, body.exchangeType, symbol),
            )),
          };
        }

        this.setOrderbookState(
          new OrderbookType(
            body.exchangeName,
            body.exchangeType,
            symbol,
            true,
            orderbook.buys,
            orderbook.sells,
            orderbook.incompleteBuys,
            orderbook.incompleteSells,
          ),
        );
      }
    },
    setOrderbook(body: OrderbookServerResp) {
      const newOrderbookType = convertOrderbookTypeServer(
        body.orderbook, body.exchangeName, body.exchangeType, body.symbol,
      );
      this.setOrderbookState(newOrderbookType);
    },
    setOrderbookState(orderbook: OrderbookType) {
      if (this.orderbooks[orderbook.exchangeName] === undefined) {
        this.orderbooks[orderbook.exchangeName] = {};
      }

      if (this.orderbooks[orderbook.exchangeName][orderbook.exchangeType] === undefined) {
        this.orderbooks[orderbook.exchangeName][orderbook.exchangeType] = {};
      }

      this.orderbooks[orderbook.exchangeName][orderbook.exchangeType][orderbook.symbol] = orderbook;
    },
    deleteOrderbook(body: OrderbookServerResp) {
      if (this.orderbooks?.[body.exchangeName]?.[body.exchangeType]?.[body.symbol] !== undefined) {
        delete this.orderbooks[body.exchangeName][body.exchangeType][body.symbol];
      } else {
        const wsStore = useWebSocketStore();
        wsStore.send({
          category: 'unsubscribe_from_symbol',
          body: JSON.stringify({
            exchangeName: body.exchangeName,
            exchangeType: body.exchangeType,
            symbol: body.symbol,
          }),
        });
      }
    },
    changes(body: OrderbookChangesServerResp) {
      const settingsStore = useUserSettingsStore();
      const orderbook = this.orderbooks?.[body.exchangeName]?.[body.exchangeType]?.[body.symbol];

      if (orderbook !== undefined) {
        const orderbookRowChanges: OrderbookRowChange[] = [];

        for (const change of body.updates) {
          let chosenAssetQuantity = '0';

          if (change.quantity !== '0') {
            chosenAssetQuantity = String(settingsStore.convertSymbolQuantityToChosenAsset(
              parseFloat(change.quantity),
              parseFloat(change.price),
              new ChosenSymbol(body.exchangeName, body.exchangeType, body.symbol),
            ));
          }

          const newChange = new OrderbookRowChange(
            '',
            '',
            change.side,
            change.price,
            change.quantity,
            chosenAssetQuantity,
          );

          orderbookRowChanges.push(newChange);
        }

        this.setOrderbookValues({ orderbook, changes: orderbookRowChanges });
      } else {
        const wsStore = useWebSocketStore();
        wsStore.send({
          category: 'unsubscribe_from_symbol',
          body: JSON.stringify({
            exchangeName: body.exchangeName,
            exchangeType: body.exchangeType,
            symbol: body.symbol,
          }),
        });
      }
    },
    setOrderbookValues({ orderbook, changes }: { orderbook: OrderbookType, changes: OrderbookRowChange[] }) {
      for (const { side, price, quantity, chosenAssetQuantity } of changes) {
        if (side === 'buy') {
          if (quantity === '0') {
            delete orderbook.buys[price];
          } else {
            orderbook.buys[price] = {
              quantity: quantity,
              chosenAssetQuantity: chosenAssetQuantity,
            };
          }
        } else {
          if (quantity === '0') {
            delete orderbook.sells[price];
          } else {
            orderbook.sells[price] = {
              quantity: quantity,
              chosenAssetQuantity: chosenAssetQuantity,
            };
          }
        }

        if (!orderbook.recentChanges.has(price)) {
          orderbook.recentChanges.set(price, {
            buyQuantity: '',
            sellQuantity: '',
            price: price,
            side: side,
            quantity: quantity,
            chosenAssetQuantity: chosenAssetQuantity,
          });
        }

        // Rather than setting quantity, we simply set chosenAssetQuantity, since that
        // is what lightweight-charts uses.
        const orderbookChange = orderbook.recentChanges.get(price);
        if (side == SIDE.BUY) {
          orderbookChange.buyQuantity = chosenAssetQuantity;
        } else {
          orderbookChange.sellQuantity = chosenAssetQuantity;
        }
      }
    },
    clearRecentChanges(orderbook: OrderbookType) {
      if (orderbook !== undefined) {
        orderbook.recentChanges = new Map();
      }
    },
  },
});
